<template>
  <b-card class="mt-1">
    <div class="demo-vertical-spacing mt-1 mr-2">
      <b-form-input 
        placeholder="Nome" 
        class="mt-1" 
        v-model="dateUsers.name" 
      />
      <b-form-input
        placeholder="E-mail"
        class="mt-1"
        type="email"
        v-model="dateUsers.email"
      />
      <b-form-input
        placeholder="Senha"
        class="mt-1"
        type="password"
        v-model="dateUsers.password"
      />
      <b-form-input
        placeholder="Confirmação da senha"
        class="mt-1"
        type="password"
        v-model="dateUsers.c_password"
      />
      <b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-1"
          @click="storeUsers"
        >
          Cadastrar
        </b-button>

        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
          Cancelar
        </b-button>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      dateUsers: {
        name: null,
        email: null,
        password: null,
        c_password: null,
        tenant_id: this.$route.params.id,
      },
    };
  },
  methods: {
    ...mapActions("tenants", ["storeUser"]),
    storeUsers() {
      this.storeUser({
        ...this.dateUsers,
      });
    },
  },
};
</script>