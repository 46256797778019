<template>
  <div>
    <template>
      <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <!-- Dados Clientes -->
          <b-card v-if="tenant">
            <b-row>
              <!-- User Info: Left col -->
              <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <b-avatar
                    v-if="!tenant.photo"
                    :src="require('@/assets/images/logo/logo.png')"
                    class="bg-avatar bg-color-avatar"
                    size="80px"
                    rounded
                  />
                  <b-avatar
                    v-else
                    :src="tenant.photo"
                    class="bg-avatar bg-color-avatar"
                    size="104px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">{{ tenant.company_name }}</h4>
                    </div>
                    <div class="d-flex flex-wrap"></div>
                  </div>
                </div>
                <div class="d-flex align-items-center mt-2 mb-2">
                  <div class="d-flex align-items-center ml-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      block
                      v-b-modal.modal-clients
                    >
                      Clientes
                    </b-button>
                    <clients-view :clients="tenant.clients" @clientFilter="FilterClient"/>
                  </div>
                  <div class="d-flex align-items-center ml-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      block
                      v-b-modal.modal-wallet-view
                    >
                      Carteiras
                    </b-button>
                    <wallet-view :portfolios="tenant.portfolios" @portfoliosFilter="FilterPortfolios"/>
                  </div>
                </div>
              </b-col>

              <!-- Right Col: Table -->
              <b-col cols="12" xl="6">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CheckIcon" class="mr-75" />
                      <span class="font-weight-bold">Status</span>
                    </th>
                    <td class="pb-50 text-capitalize" v-if="tenant.status == '0'">
                      <b-badge variant="danger" class="badge-glow">
                        Desativado
                      </b-badge>
                    </td>
                    <td class="pb-50 text-capitalize" v-if="tenant.status == '1'">
                      <b-badge variant="success" class="badge-glow">
                        Ativado
                      </b-badge>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="FlagIcon" class="mr-75" />
                      <span class="font-weight-bold">Endereço</span>
                    </th>
                    <td class="pb-50 text-capitalize" v-if="tenant.street == ''">
                      <feather-icon icon="FrownIcon" /> 
                      Sem endereço cadastrado. 
                    </td>
                    <td class="pb-50 text-capitalize" v-else>
                      {{ tenant.street +' Nº '+ tenant.number +', '+ tenant.city +' - '+ tenant.state }} 
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="AtSignIcon" class="mr-75" />
                      <span class="font-weight-bold">Email</span>
                    </th>
                    <td class="pb-50" v-if="tenant.street == ''">
                      <feather-icon icon="FrownIcon" />
                      Sem E-mail cadastrado.
                    </td>
                    <td class="pb-50" v-else>{{ tenant.email }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="PhoneIcon" class="mr-75" />
                      <span class="font-weight-bold">Telefone</span>
                    </th>
                    <td class="pb-50" v-if="tenant.phone == ''">
                      <feather-icon icon="FrownIcon" />
                      Sem telefone cadastrado.
                    </td>
                    <td class="pb-50" v-else>{{ tenant.phone }}</td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      <span class="font-weight-bold">Desde</span>
                    </th>
                    <td>{{ tenant.created_at | dateFormat }}</td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card>

          <!-- Fim dados do cliente -->
        </b-col>

        <b-col cols="12" md="5" xl="3" lg="4">
          <!-- Começo dados do plano -->

          <b-card no-body class="border-primary">
            <b-card-header
              class="d-flex justify-content-between align-items-center pt-52 pb-75"
              v-if="tenant.plan"
            >
              <h5>{{ tenant.plan.name }}</h5>
              <b-badge variant="light-primary">
                {{ tenant.plan.value | moneyFormat }}
              </b-badge>
            </b-card-header>
            <b-card-body>
              <ul class="list-unstyled">
                <li>
                  <feather-icon icon="UserIcon" class="mr-75" />
                  <span class="align-middle">5 Usuários</span>
                </li>
                <li class="my-25">
                  <feather-icon icon="DatabaseIcon" class="mr-75" />
                  <span class="align-middle">10k de envios</span>
                </li>
                <li>
                  <feather-icon icon="PhoneCallIcon" class="mr-75" />
                  <span class="align-middle">Suporte</span>
                </li>
              </ul>
              <!-- <statistic-card-horizontal
                icon="ServerIcon"
                color="success"
                statistic="1.2gb"
                statistic-title="Memory Usage"
              /> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                v-b-modal.modal-historic
              >
                Histórico de faturas
              </b-button>
            </b-card-body>
            <historic :invoices="tenant.invoices" @historicFilter="FilterHistoric"/>
          </b-card>
          <!-- Fim dados do plano -->
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6">
          <!-- Começo do relatorio -->
          <b-card title="Relatorio de consumo" class="mb-2">
            <b-row class="p-1">
              <div v-if="!showSearch">
                <b-button
                  variant="relief-success"
                  class="mr-2"
                  @click="showSearch = true"
                >
                  <feather-icon icon="TrendingUpIcon" />
                  Ver consumo anual
                </b-button>
              </div>
              <div v-else>
                <b-button
                  variant="relief-info"
                  class="mr-2"
                  @click="showSearch = false"
                >
                  <feather-icon icon="PieChartIcon" />
                  Ver consumo mensal
                </b-button>
              </div>
            </b-row>
            <b-row class="justify-content-md-center responsive">
              <b-col v-show="!showSearch">
                <!-- Grafico radar -->
                <radar v-if="consu_tenant" :consu_tenant="consu_tenant" />
              </b-col>
              <b-col v-show="showSearch">
                <!-- Grafico doughnut -->
                <doughnut v-if="consu_tenant_yearly" :consu_tenant_yearly="consu_tenant_yearly" />
              </b-col>
            </b-row>
          </b-card>
           <!-- Fim do relatorio -->
           <!-- Permissões -->
            <!-- <permissions /> -->
         
        </b-col>

        <!-- User -->

        <b-col cols="12" lg="6">
          <!-- Começo do Usuario -->
          <b-card no-body>
            <b-card-body>
              <b-card-title>Usuários</b-card-title>
                <table-user v-if="tenant.users" :users="tenant.users" @userFilter="FilterUser"/>
            </b-card-body>
          </b-card>

          <!-- Permissões -->
          <!-- <permissions /> -->
        </b-col>
      </b-row>

      <!-- synchronization -->

      <b-card title="Sincronização">
        <synchronization v-if="tenant.syncs" :data_syncs="tenant.syncs" />
      </b-card>
      </b-overlay>
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { ref, onUnmounted } from "@vue/composition-api";
import Historic from "@/components/tenants/modals/Historic";
import Synchronization from "@/components/tenants/charts/Synchronization";
import Radar from "@/components/tenants/charts/ConsuTenant.vue";
import Doughnut from "@/components/tenants/charts/ConsuTenantYearly";
import Permissions from "@/components/tenants/Permissions.vue";
import TableUser from "@/components/tenants/user/TableUser.vue";
import StoreUser from "@/components/tenants/user/StoreUser";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import ClientsView from "@/components/tenants/modals/ClientsView.vue";
import WalletView from "@/components/tenants/modals/WalletView.vue";
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    Swiper,
    SwiperSlide,
    Historic,
    Synchronization,
    Radar,
    Doughnut,
    Permissions,
    TableUser,
    StoreUser,
    ClientsView,
    WalletView,
    StatisticCardHorizontal
  },
  data() {
    return {
      showSearch: false,
      showCreate: false,
      selectedcheck: [],
      selected: null,
      isLoading: true,

      filter_Client: null,
      filter_portfolio: null,
      filter_historic: null,
      filter_user: null,

      id_tenant: null,
      

      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
    };
  },
  computed: {
    ...mapState("tenants", ["tenant", "consu_tenant", "consu_tenant_yearly"]),
  },
  methods: {
    ...mapActions("tenants", ["fetchConsuTenant", "fetchConsuTenantYearly", "fetchTenant"]),
    // Filter(){
    //   this.fetchTenant({
    //     filter_client: this.filter_Client,
       
    //   })
    // },


    FilterClient(value){
      this.fetchTenant({
        generic: value,
        tenant_id: this.$route.params.id
      })
      // this.filter_Client = value
      console.log('Pai Client', value);
    },
    FilterPortfolios(value){
      this.filter_portfolio = value
      console.log('Pai Portfolio', value);
    },
    FilterHistoric(value){
      this.filter_historic = value
      console.log('Pai Historic', value);
    },
    FilterUser(value){
      this.filter_user = value
      console.log('Pai User', value);
    }
  },
  created() {
    this.id_tenant = this.$route.params.id;
    this.fetchConsuTenant({
      tenant_id: this.$route.params.id,
    });
     this.fetchConsuTenantYearly({
      tenant_id: this.$route.params.id,
    });
    this.fetchTenant({
      tenant_id: this.$route.params.id,
    });
    
  },
  watch: {
    tenant(){
      this.isLoading = false
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bg-color-avatar {
  background: none !important;
}
</style>
