<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-sub-title class="mb-25"> Total de envios no ano de {{ new Date().getFullYear() }}

        </b-card-sub-title>
        <b-card-title class="font-weight-bolder"> {{ amountYearly }} </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="350"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },
  props: {
    consu_tenant_yearly: {
      default: function () {
        return []
      }
    }
  },
  
  data() {
    return {
      consu_yearly: this.consu_tenant_yearly,
      amountYearly: null,

      rangePicker: ["2019-05-01", "2019-05-10"],

      barChart: {
        series: [
          {
            data: [100, 100, 100, 100, 100, 100, 100]
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '5%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: ['Email', 'Carta', 'SMS', 'WhatsApp', 'Ligacao', 'Serasa']
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },

      
    };
  },
  beforeMount() {
    this.barChart.series[0].data = this.consu_yearly.map(consumption => {
       this.amountYearly += consumption.amount
        return consumption.amount
    })
  },
  watch: {
    consu_yearly(val) {
      this.barChart.series[0].data = val.map(consumption => {
        this.amountYearly  += consumption.amount
        return consumption.amount
      })
    }
  },
};
</script>
