<template>
  <b-modal
    id="modal-wallet-created"
    ok-only
    ok-title="Fechar"
    centered
    size="md"
    title="Cadastrar carteira"
  >
    <b-form-input placeholder="Nome" class="mt-1" />
    <b-form-input placeholder="Cor" class="mt-1" />
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="relief-danger" class="mr-1" ok-only>
          Cancelar
        </b-button>
        <b-button variant="relief-success"> Cadastrar </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      showSearch: false,
    };
  },
  components: {
    BButton,
    BModal,
  },
  methods: {},
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
 