<template>
  <b-modal
    id="modal-clients"
    ok-only
    ok-title="Fechar"
    centered
    size="xl"
    title="Lista de Clientes"
  >
    <b-row class="p-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        v-b-modal.modal-created-clients
      >
        <feather-icon icon="PlusIcon" />
        Clientes
      </b-button>
      <clients-created />
      <div v-if="!showSearch">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-success"
          class="btn-icon rounded-circle"
          @click="showSearch = true"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
      </div>
      <div v-else>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-danger"
          class="btn-icon rounded-circle"
          @click="showSearch = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <b-col v-show="showSearch">
        <b-form-input 
          placeholder="Pesquisar"
          debounce="800"
          type="search"
          v-model="search"
          @change="filterItems"
          
        />
      </b-col>
    </b-row>
    <!-- <b-overlay :show="isLoading" opacity="0.70" rounded="sm"> -->
    <b-table-simple
      striped
      stacked="md"
      noDataText="Você ainda não possui clientes cadastrados."
      class="mt-2 responsive"
      id="tableClients"
      :item="clients.data"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <b-thead class="text-center">
        <b-th>Nome</b-th>
        <b-th>Telefone</b-th>
        <b-th>E-mail</b-th>
        <b-th>CPF/CNPJ</b-th>
        <b-th>Data de registro</b-th>
        <b-th>Ação</b-th>
      </b-thead>
      <b-tbody>
        <b-tr :key="i" v-for="(client, i) in clients.data">
          <b-td> {{ client.name }} </b-td>
          <b-td> {{ client.phone }} </b-td>
          <b-td> {{ client.email }} </b-td>
          <b-td> {{ client.cpf_cnpj }} </b-td>
          <b-td> {{ client.created_at | dateFormat }} </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-warning"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              v-b-modal.modal-delete-tenants
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- </b-overlay> -->
    <b-pagination
      @change="handlePageChange"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="clients.total"
      aria-controls="tableClients"
      class="mt-1"
    />
  </b-modal>
</template>
<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ClientsCreated from "./ClientsCreated.vue";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      search: null,
      // isLoading: true,
      id_tenant: this.$route.params.id,
      showSearch: false,
    };
  },
  methods: {
    handlePageChange(value) {
      this.$parent.fetchTenant({
        page: value,
        tenant_id: this.id_tenant,
      });
    },
    filterItems(value){
      let filter = value;
      console.log("Filho", filter),
      this.$parent.fetchTenant({
        page: 1,
        generic: value,
        tenant_id: this.id_tenant,
      })
      this.$emit("clientFilter", filter);
      return filter;
    }
  },
  watch: {
    search(val) {
      this.currentPage = 1;
      this.$parent.fetchTenant({
        page: 1,
        tenant_id: this.id_tenant,
      })
    },
  },
  components: {
    BButton,
    BModal,
    ClientsCreated,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    clients: {
      default: function () {
        return []
      }
    },
  },
  computed: {
    
  }
};
</script>
 