<template>
  <b-modal
    id="modal-historic"
    ok-only
    ok-title="Fechar"
    centered
    size="xl"
    title="Histórico de faturas"
  >
    <b-input-group class="input-group-merge">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        placeholder="Pesquisar"
        debounce="800"
        type="search"
        v-model="search"
        @change="filterItems"
      />
    </b-input-group>
    <b-table-simple
      responsive
      striped
      stacked="md"
      noDataText="Você ainda não possui clientes cadastrados."
      class="text-center mt-2"
      id="tableInvoice"
      :item="invoices.data"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <b-thead class="text-center">
        <b-th>#</b-th>
        <b-th>Status</b-th>
        <b-th>Valor</b-th>
        <b-th>Data de emissão</b-th>
        <b-th>Data de vencimento</b-th>
        <b-th>Ação</b-th>
      </b-thead>
      <b-tbody>
        <b-tr :key="indextr" v-for="(invoice, indextr) in invoices.data">
          <b-td>{{ invoice.code }}</b-td>

          <b-td v-if="invoice.status == 'Pago'" class="text-center">
            <b-avatar
              size="32"
              variant="success"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-success
              title="Fatura paga"
            >
              <feather-icon icon="CheckIcon" />
            </b-avatar>
          </b-td>
          <b-td v-if="invoice.status == 'Aberto'" class="text-center">
            <b-avatar
              size="32"
              variant="warning"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-warning
              title="Fatura em aberto"
            >
              <feather-icon icon="ActivityIcon" />
            </b-avatar>
          </b-td>
          <b-td v-if="invoice.status == 'Atraso'" class="text-center">
            <b-avatar
              size="32"
              variant="danger"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-danger
              title="Fatura pendente"
            >
              <feather-icon icon="AlertTriangleIcon" />
            </b-avatar>
          </b-td>

          <b-td>{{ invoice.value_total | moneyFormat }}</b-td>
          <b-td> {{ invoice.date_issue | dateFormat }} </b-td>
          <b-td> {{ invoice.date_due | dateFormat }} </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-dark"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-dark"
              class="btn-icon rounded-circle"
              v-b-modal.modal-view-invoice
              @click="ViewInvoiceModal(invoice.id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-pagination
      @change="handlePageChange"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="invoices.total"
      aria-controls="tableInvoice"
      class="mt-1"
    />
    <div v-if="invoices_id != '0'">
      <view-invoices :view="invoices_id" ref="viewInvoice"/>
    </div>
    
  </b-modal>
</template>
<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ViewInvoices from "@/components/tenants/modals/ViewInvoices";

export default {
  components: {
    BButton,
    BModal,
    ViewInvoices,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      search: null,

      invoices_id: 0,
    };
  },
  methods: {
    filterItems(value){
      let filter = value;
      console.log('Filho', filter)
      this.$parent.fetchTenant({
        page: 1,
        generic: value,
        tenant_id: this.$route.params.id,
      })
      return filter;
    },
    ViewInvoiceModal(id){
      this.invoices_id = id; 
      this.$refs.viewInvoice.fetchInvoice({
        invoice_id: this.invoices_id,
      })
      console.log(this.invoice_id)
    },
    handlePageChange(value) {
      this.$parent.fetchTenant({
        page: value,
        tenant_id: this.$route.params.id,
      });
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    invoices: {
      default: function () {
        return [];
      },
    },
  },
};
</script>
 