<template>
  <b-modal
    id="modal-wallet-view"
    ok-only
    ok-title="Fechar"
    centered
    size="xl"
    title="Carteiras"
  >
    <b-row class="p-1">
      <div v-if="!showSearch">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-success"
          class="btn-icon rounded-circle"
          @click="showSearch = true"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
      </div>
      <div v-else>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-danger"
          class="btn-icon rounded-circle"
          @click="showSearch = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <b-col v-show="showSearch">
        <b-form-input
          placeholder="Pesquisar"
          debounce="800"
          type="search"
          v-model="search"
          @change="filterItems"
        />
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          v-b-modal.modal-wallet-created
        >
          <feather-icon icon="PlusIcon" />
          Carteiras
        </b-button>
        <wallet-created />
      </b-col>
    </b-row>

    <b-table-simple
      responsive
      striped
      stacked="md"
      noDataText="Você ainda não possui clientes cadastrados."
      class="mt-2"
      id="tableWallet"
      :item="portfolios.data"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <b-thead class="text-center">
        <b-th> Nome </b-th>
        <b-th> Cor </b-th>
        <b-th> Ação </b-th>
      </b-thead>
      <b-tbody>
        <b-tr :key="i" v-for="(portfolio, i) in portfolios.data">
          <b-td> {{ portfolio.name }} </b-td>


          <b-td class="text-center" v-if="portfolio.color == '#eff52f'">
            <b-badge
              variant="warning"
              class="badge-glow"
            >
              <feather-icon icon="CreditCardIcon" />
            </b-badge>
          </b-td>
          <b-td class="text-center" v-if="portfolio.color == '#28c76f'">
            <b-badge
              variant="success"
              class="badge-glow"
            >
              <feather-icon icon="CreditCardIcon" />
            </b-badge>
          </b-td>
          <b-td class="text-center" v-if="portfolio.color == '#ea5455'">
            <b-badge
              variant="danger"
              class="badge-glow"
            >
              <feather-icon icon="CreditCardIcon" />
            </b-badge>
          </b-td>
          <b-td class="text-center" v-if="portfolio.color == '#7367f0'">
            <b-badge
              variant="info"
              class="badge-glow"
            >
              <feather-icon icon="CreditCardIcon" />
            </b-badge>
          </b-td>


          <b-td class="text-center">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-warning"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              v-b-modal.modal-delete-tenants
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple> 
    <b-pagination
      @change="handlePageChange"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="portfolios.total"
      aria-controls="tableWallet"
      class="mt-1"
    />
  </b-modal>
</template>
<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import WalletCreated from './WalletCreated.vue';

export default {
  data() {
    return {
      showSearch: false,
      search: null,

      perPage: 10,
      currentPage: 1,
      totalRows: null,
    };
  },
  components: {
    BButton,
    BModal,
    WalletCreated
  },
  methods: {
    filterItems(value){
      let filter = value;
      console.log(filter)
      this.$emit("portfoliosFilter", filter);
      return filter;
    },
    handlePageChange(value) {
      this.$parent.fetchTenant({
        page: value,
        tenant_id: this.$route.params.id,
      });
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    portfolios: {
      default: function () {
        return []
      }
    }
  },
};
</script>
 