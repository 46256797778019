<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-sub-title class="mb-25"> Total de envios no mês de 
          {{ new Date().getMonth() == 0 ? "Janeiro" : "" }}
          {{ new Date().getMonth() == 1 ? "Fevereiro" : ""}}
          {{ new Date().getMonth() == 2 ? "Março" : ""}}
          {{ new Date().getMonth() == 3 ? "Abril" : "" }}
          {{ new Date().getMonth() == 4 ? "Maio" : "" }}
          {{ new Date().getMonth() == 5 ? "Junho" : "" }}
          {{ new Date().getMonth() == 6 ? "Julho" : "" }}
          {{ new Date().getMonth() == 7 ? "Agosto" : "" }}
          {{ new Date().getMonth() == 8 ? "Setembro" : "" }}
          {{ new Date().getMonth() == 9 ? "Outubro" : "" }}
          {{ new Date().getMonth() == 10 ? "Novembro" : "" }}
          {{ new Date().getMonth() == 11 ? "Dezembro" : "" }}


        </b-card-sub-title>
        <b-card-title class="font-weight-bolder"> {{ amountMonth }} </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="!isLoading"
        type="bar"
        height="350"
        :options="barChart.chartOptions"
        :series="barChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },
  props: {
    consu_tenant: {
      default: function () {
        return []
      }
    }
  },
  
  data() {
    return {
      consumptions: this.consu_tenant,
      isLoading: true,
      amountMonth: 0,

      rangePicker: ["2019-05-01", "2019-05-10"],

      barChart: {
        series: [
          {
            data: [100, 100, 100, 100, 100, 100, 100]
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
          },
          xaxis: {
            categories: ['Email', 'Carta', 'SMS', 'WhatsApp', 'Ligacao', 'Serasa']
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },

      
    };
  },
  watch: {
    consu_tenant(val) {
      this.barChart.series[0].data = val.map(consumption => {
        this.amountMonth += consumption.amount
        return consumption.amount
      })
      this.isLoading = false
    }
  },
};
</script>
