<template>
  <b-card class="mr-1">
    <!-- <b-input-group class="input-group-merge mb-1">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input 
        placeholder="Pesquisar"
        v-model="search"
        debounce="800"
        type="search"
         @change="filterItems"
      />
    </b-input-group> -->
    <b-row class="p-1">
      <div v-if="!showCreate">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="showCreate = true"
        >
          <feather-icon icon="PlusIcon" />
          Usuários
        </b-button>
      </div>
      <div v-else>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="mr-1"
          @click="showCreate = false"
        >
          <feather-icon icon="PlusIcon" />
          Usuários
        </b-button>
      </div>
      <div v-if="!showSearch" v-show="!showCreate">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-success"
          class="btn-icon rounded-circle"
          @click="showSearch = true"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
      </div>
      <div v-else v-show="!showCreate">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-danger"
          class="btn-icon rounded-circle"
          @click="showSearch = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <b-col v-show="showSearch">
        <b-form-input
          placeholder="Pesquisar"
          debounce="800"
          type="search"
          v-model="search"
          @change="filterItems"
        />
      </b-col>
    </b-row>
    <div v-show="!showCreate">
      <b-table-simple
        striped
        stacked="md"
        noDataText="Você ainda não possui usuários cadastrados."
        class="responsive"
        id="tableUsers"
        :item="users.data"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <b-thead class="text-center">
          <b-th>Nome</b-th>
          <b-th>Email</b-th>
          <b-th>Data de registro</b-th>
          <b-th>Ação</b-th>
        </b-thead>
        <b-tbody>
          <b-tr :key="i" v-for="(user, i) in users.data">
            <b-td>{{ user.name }}</b-td>
            <b-td>{{ user.email }}</b-td>
            <b-td>{{ user.created_at | dateFormat }}</b-td>
            <b-td>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="users.total"
          aria-controls="tableUsers"
          class="mt-1"
        />
      </b-table-simple>
    </div>
    <div v-show="showCreate">
      <div class="demo-vertical-spacing mt-1 mr-2">
        <b-form-input
          placeholder="Nome"
          class="mt-1"
          v-model="dateUsers.name"
        />
        <b-form-input
          placeholder="E-mail"
          class="mt-1"
          type="email"
          v-model="dateUsers.email"
        />
        <b-form-input
          placeholder="Senha"
          class="mt-1"
          type="password"
          v-model="dateUsers.password"
        />
        <b-form-input
          placeholder="Confirmação da senha"
          class="mt-1"
          type="password"
          v-model="dateUsers.c_password"
        />
        <b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            @click="storeUsers"
          >
            Cadastrar
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" @click="showCreate = false">
            Cancelar
          </b-button>
        </b-row>
      </div>
    </div>
  </b-card>
</template>
<script>
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      showSearch: false,
      showCreate: false,

      search: null,

      dateUsers: {
        name: null,
        email: null,
        password: null,
        c_password: null,
        tenant_id: this.$route.params.id,
      },
    };
  },
  methods: {
    ...mapActions("tenants", ["storeUser"]),
    storeUsers() {
      this.storeUser({
        ...this.dateUsers,
      });
    },
    filterItems(value) {
      let filter = value;
      console.log("Filho User", filter);
      this.$emit("userFilter", filter);
      return filter;
    },
    handlePageChange(value) {
      this.$parent.fetchTenant({
        page: value,
        tenant_id: this.$route.params.id,
        generic: this.search,
      });
    },
  },
  directives: {
    Ripple,
  },
  props: ["users"],
};
</script>