<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>Permissões</b-card-title>
      <b-card-sub-title>Permissão de acordo com as funções</b-card-sub-title>
    </b-card-body>
    <b-table striped responsive :items="permissionsData" class="mb-0">
      <template #cell(cargo)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox :checked="data.value" />
      </template>
    </b-table>
  </b-card>
</template>
<script>
export default {
	setup() {
    const permissionsData = [
      {
        cargo: "Admin",
        ler: false,
        Escreva: false,
        criar: false,
        deletar: false,
      },
      {
        cargo: "Staff",
        ler: false,
        Escreva: false,
        criar: false,
        deletar: false,
      },
      {
        cargo: "Author",
        ler: false,
        Escreva: false,
        criar: false,
        deletar: false,
      },
      {
        cargo: "Contributor",
        ler: false,
        Escreva: false,
        criar: false,
        deletar: false,
      },
      {
        cargo: "User",
        ler: false,
        Escreva: false,
        criar: false,
        deletar: false,
      },
    ];

    return {
      permissionsData,
    };
  },
};
</script>