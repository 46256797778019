<template>
  <b-modal
    id="modal-created-clients"
    ok-only
    ok-title="Fechar"
    centered
    size="md"
    title="Cadastro dos Clientes"
  >
    <b-form-input placeholder="Nome" class="mt-1" v-model="dateClients.name" />
    <b-form-input
      placeholder="Telefone"
      class="mt-1"
      v-model="dateClients.phone"
    />
    <b-form-input
      placeholder="E-mail"
      class="mt-1"
      v-model="dateClients.email"
    />
    <b-form-input
      placeholder="CPF/CNPJ"
      class="mt-1"
      v-model="dateClients.cpf_cnpj"
    />
    <b-form-input
      placeholder="Código"
      class="mt-1"
      v-model="dateClients.code"
    />
    <b-form-input
      placeholder="Situação"
      class="mt-1"
      v-model="dateClients.situation"
    />
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="relief-danger" class="mr-1" ok-only>
          Cancelar
        </b-button>
        <b-button variant="relief-success" @click="storeClients">
          Cadastrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { BButton, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      showSearch: false,
      dateClients: {
        name: null,
        phone: null,
        email: null,
        cpf_cnpj: null,
        code: null,
        situation: null,
        tenant_id: this.$route.params.id,
      },
    };
  },
  components: {
    BButton,
    BModal,
  },
  methods: {
    ...mapActions("tenants", ["storeClient"]),
    storeClients() {
      this.storeClient({
        ...this.dateClients,
      });
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
 