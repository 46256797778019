<template>
    <app-echart-stacked-area :option-data="option" style="width: 100%" />
</template>
<script>
import AppEchartStackedArea from "@core/components/charts/echart/AppEchartStackedArea.vue";
export default {
  components: {
    AppEchartStackedArea,
  },
  props: ['data_syncs'],
  data(){
    return{
      syncs: this.data_syncs,
      option: {
        grid: {
          width: '93%',
          left: '50px',
          right: '4%',
          containLabel: false,
        },
        xAxisData: [
        ],
        series: [
          {
            name: "Sincronização",
            type: "line",
            stack: "Total",
            areaStyle: {
              color: "#84d0ff",
            },
            color: "#84d0ff",
            showSymbol: false,
            smooth: 0.4,
            lineStyle: {
              width: 0,
            },
            data: [
            ],
          },
        ],
      },
    }
  },
  beforeMount() {
    this.option.xAxisData = this.syncs.map(function(sync) {
      return sync.created_at
    })
    this.option.series[0].data = this.syncs.map(function(sync) {
      return sync.volume
    })
  }
}
</script>